import "./App.css";

function App() {
  return (
    <div className="card">
      <div className="home">
        <div className="dp-container">
          <img
            className="dp"
            alt="Jude's display picture"
            src={`${process.env.PUBLIC_URL}/jude-dp2.png`}
          />
        </div>
        <div className="info">
          <div className="contact-info">
            <span className="name"> Jude D'silva</span>
            <span className="misc">
              <a href="tel:+919869746256">+91 9869746256</a>
            </span>
            <span className="misc">
              <a href="mailto:judedsilva1997@gmail.com">
                judedsilva1997@gmail.com
              </a>
            </span>
          </div>
          <div className="proficiency">
            <span className="profile">Full Stack Developer</span>
            <span className="title"> Proficient At - </span>
            <div className="skills-logos">
              <img src={`${process.env.PUBLIC_URL}/java.png`} alt="Java" />
              <img src={`${process.env.PUBLIC_URL}/spring.png`} alt="Spring" />
              <img src={`${process.env.PUBLIC_URL}/js.png`} alt="JavaScript" />
              <img src={`${process.env.PUBLIC_URL}/react.png`} alt="React" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
